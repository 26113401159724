<template>
    <div>
        <van-nav-bar class="navbar" title="房屋详情" fixed left-arrow  @click-left="leftReturn" />
      <div class="more" @click="more">更多</div>
        <div class="swipe" >
          <img :src="house_image" v-if="roomImages.length == 0">
            <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" >
                <van-swipe-item v-for="(item,index) in roomImages" :key="index">
                    <div class="image">
                        <img :src="item.roomPhotoPath">
                    </div>
                </van-swipe-item>
            </van-swipe>
            <div class="top-title">
                <div class="small-text-10">
                    {{roomImages.length}}图
                </div>
            </div>
        </div>
        <!--收藏分享部分-->
        <div class="house-describe">
            <div class="house-describe-position">
               <!--商圈名称-->
             <span>{{initroom.houseTypeStr}}</span><span>·</span> <span class="house-describe-position-span">{{initroom.estateName}} - {{initroom.roomHouseFuzzyAddress}}</span>
            </div>
            <div class="house-describe-icon1" @click="collect">
                <div :class="collectActive?'house-describe-icon1-leftActive':'house-describe-icon1-left'"></div>
                <div class="house-describe-icon1-text">
                    <div class="small-text-8" :class="{'small-text-8Active':collectActive}">{{roomCollection_id?'已收藏':'收藏'}}</div>
                </div>
            </div>
<!--            <div class="house-describe-icon2">-->
<!--                <div class="house-describe-icon1-right"></div>-->
<!--                <div class="house-describe-icon1-text">-->
<!--&lt;!&ndash;                    <div class="small-text-8">分享</div>&ndash;&gt;-->
<!--                </div>-->
<!--            </div>-->
        </div>
        <!--价格部分-->
        <div class="infos" style="display: flex;align-items: center;justify-content: space-between">
          <div>
            <div class="infos-price">{{initroom.roomRent}}元</div>
            <div class="infos-month">/月</div>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;margin-left: 16px">
            <div class="dispose" style="background-color: #DEEBFB;color: #86B3E8;">{{initroom.subTypeStr}}</div>
            <div class="dispose" >{{initroom.houseTypeStr}}</div>

          </div>
<!--            <div :class="item==='地铁房'?'infos-info-1':'infos-info-2'" v-for="(item,index) in roomFeatureList" :key="index">-->
<!--                <div class="smallText-10">-->
<!--                    {{item.roomCharacteristicName}}-->
<!--                </div>-->
<!--            </div>-->
        </div>
        <!--详细描述部分-->
        <div class="details">
            <div class="details-detail-left">
                <div class="details-detail-top">使用面积</div>
                <div class="details-detail-bottom">{{initroom.roomArea}}m²</div>
            </div>
            <div class="details-line"></div>
            <div class="details-detail">
                <div class="details-detail-top">户型</div>
                <div class="details-detail-bottom">{{initroom.roomApartment}}</div>
            </div>
            <div class="details-line"></div>
            <div class="details-detail">
                <div class="details-detail-top">楼层</div>
                <div class="details-detail-bottom">{{initroom.roomFloor}}/{{initroom.totalFloor}}</div>
            </div>
        </div>
        <!--入住时间-->
        <div class="inTime">
            <div class="inTime-icon"></div>
            <div class="inTime-info">{{initroom.roomDetailedAddress}}</div>
        </div>
        <!--具体地址-->
        <div>
            <div class="position">
                <div class="position-icon"></div>
                <div class="position-text">位于{{initroom.roomFuzzyAddress}}</div>
                <div class="position-link" @click="showMap">查看地址</div>


            </div>
        </div>
        <!--家具具体信息部分-->
        <div class="houseDetails">
            <div class="houseDetails-text" v-if="roomItemList.length>0">房屋信息</div>
            <div class="furnitures">
                <div class="furniture-one" v-for="(item,index) in roomItemList" :key="index">
                    <div class="furniture-one-icon">
<!--                        <img src="../../../assets/images/houseResourcesImg/bed.png">-->
                        <img :src="item.itemPhotoPath">
                    </div>
                    <div class="furniture-one-text">{{item.itemDescription}}</div>
                </div>
            </div>
            <div class="houseDetails-text" v-if="roomInfoList">房间信息</div>
            <div >
                <div class="furniture-two" v-for="(item,index) in roomInfoList" :key="index">
                    <div class="furniture-two-icon">
                        <img :src="item.gender=='女'?nvsheng:nansheng">

                    </div>
                    <div class="furniture-two-roomName">{{item.roomName}}</div>
                    <div class="furniture-two-gender">{{item.gender+'生'}}</div>
                    <div class="furniture-two-month">{{item.month+'个月'}}</div>
                </div>
            </div>
            <!--房屋描述-->
            <div class="houseDetails-describe" v-if="initroom.roomDescription">房屋描述</div>
            <div class="houseDetails-describe-details" :class="showActive?'show':'hide'">
                {{initroom.roomDescription}}
            </div>
            <div class="bottom-box">
                <div class="showOrHidden" @click="showOrHidden" v-if="initroom.roomDescription">{{showActive?'收起':'显示全部'}}</div>
            </div>
            <!--底部图片-->
            <div class="bottom-img"></div>
        </div>
        <!--底部固定栏-->
        <div class="bottom-fixed">
            <div class="bottom-fixed-signed" v-if="roomStatus!='8'||roomStatusStr=='预到期' || roomStatusStr=='转租'">
                <div class="bottom-fixed-signed-icon"></div>
                <div class="bottom-fixed-text">
                    <div @click="signContract" class="bottom-fixed-text-11">{{roomStatus=='8' && roomStatusStr=='转租'?'转租':'签约'}}</div>
                </div>
            </div>
            <div class="bottom-fixed-order" v-if="roomStatus!='8'||roomStatusStr=='预到期'|| roomStatusStr=='转租'">
                <div class="bottom-fixed-order-icon"></div>
                <div class="bottom-fixed-text">
                    <div @click="scheduled" class="bottom-fixed-text-11">预定</div>
                </div>
            </div>
            <div @click="appointmentSeeHouse" class="bottom-fixed-orderLook" v-if="roomStatus!='8'||roomStatusStr=='预到期'|| roomStatusStr=='转租'">预约看房</div>
            <div @click="telephoneConsultation" class="bottom-fixed-phoneRelation">电话咨询</div>
        </div>
      <!--拨打电话弹窗-->
      <dial-mobile
          :ownerMobile="temporaryOwnerMobile"
          :userName="temporaryOwnerName"
          :dialogTitle="dialogTitle"
          :mobileShow="isMobileShow"
          @hideDialog="hideDialog"
      >
      </dial-mobile>
    </div>
</template>

<script>
    import { NavBar,Swipe, SwipeItem,Notify} from 'vant';
    import {roomDetail,queryHouseType,getHouseAddress,addRoomCollection,delRoomCollection} from "../../../getData/getData";
    import {getStaffId, globaluserId, responseUtil} from "../../../libs/rongxunUtil";
    import dialMobile from '../../../components/rongxun/rx-dialMobile/dialMobile'
    export default {
        name: "houseDetails",
        data(){
          return{
            roomStatus:'',
            roomStatusStr:'',
            isShowMap:false,
            estateName:'',
            room_id:'',
            roomCollection_id:'',
            temporaryOwnerMobile:'',
            temporaryOwnerName:'',
            dialogTitle:'',
            longitude:'',
            latitude:'',
            isMobileShow: false,
            setHouse_id:'',
                initroom:[{
                  userType:'' ,          //	用户类型
                  roomCollection_id:'' , //	房源收藏id
                  room_id:'' ,           //	房源id
                  roomType:'' ,          //	房源类型
                  roombusinessCircleName:'' ,//	商圈名称
                  chargeStaffMobile:'' , //负责人电话
                  roomFuzzyAddress:'' ,  //	房源模糊地址
                  roomApartment:'' ,     //	房源户型
                  roomArea:'' ,          //	房源面积
                  totalFloor:'' ,        //	房源总层数
                  roomFloor:'' ,         //	房源楼层
                  roomDescription:'' ,   //	房屋描述
                  longitude:'' ,         //	经度
                  latitude:'' ,          //	纬度
                  PlatformSupport:'' ,   //	平台保障(富文本)
                  isCollect:'' ,         //	是否被收藏(0 否 1 是)
                  roomEntryTime:'' ,     //	可入住时间
                  roomRent:'' ,          //	租金
                }],
                roomImages:[],
                roomItemList:[],
                roomInfoList:[],
                roomFeatureList:[],
              showActive:false,//默认隐藏
              collectActive:false,//默认未收藏
              house:{//房屋属性
                  houseType:'合租',
                  housePosition:'天通苑南 天通苑西区 朝南主卧·五居室 2号房间',
                  housePrice:'809',
                  houseUsingArea:'16.8',
                  houseRoomType:'5室2厅1卫',
                  houseFloor:'5',
                  houseTotalFloors:'7',
                  houseCanIn:'详细地址',
                  houseRealPosition:'沙河口区/春柳街道',
                  houseDetail:'惹我收到政府的深V惹我收到政府的深V惹我收到政府的深V惹我收到政府的深V惹我收到政府的深惹我收到政府的深V惹我收到政府的深V惹我收到政府的深V惹我收到政府的深V惹我收到政府的深V'
              },
              houseGoods:['飘窗','loft','地铁房'],

              houseImages:[
                  {url:require('../../../assets/images/houseImg.png')},{url:require('../../../assets/images/houseImg.png')},
                  {url:require('../../../assets/images/houseImg.png')},{url:require('../../../assets/images/houseImg.png')}
              ],
              furnitureList:[
                  {name:'床',url:require('../../../assets/images/houseResourcesImg/bed.png')},
                  {name:'洗衣机',url:require('../../../assets/images/houseResourcesImg/washing Machine.png')},
                  {name:'微波炉',url:require('../../../assets/images/houseResourcesImg/microwave.png')},
                  {name:'书桌',url:require('../../../assets/images/houseResourcesImg/table.png')},
                  {name:'沙发',url:require('../../../assets/images/houseResourcesImg/sofa.png')},
                  {name:'冰箱',url:require('../../../assets/images/houseResourcesImg/refrigerator.png')},
                  {name:'马桶',url:require('../../../assets/images/houseResourcesImg/toilet.png')}
              ],
            house_image:require('../../../assets/images/house_image.png'),
               nvsheng:require('../../../assets/images/nvsheng.png'),
               nansheng:require('../../../assets/images/nansheng.png')
               //roomId:''         //赵
          }
        },
            mounted: function () {
              console.log(this.$route.query)
                    // 初始化
                    let initData = {}
                    initData.user_id = globaluserId()   //赵
                    //initData.user_id = '116'  //原
                    // 接收参数
                    initData.room_id =  this.$route.query.room_id
                    this.roomStatus = this.$route.query.roomStatus
                    this.roomStatusStr = this.$route.query.roomStatusStr
                    this.roomId=initData.room_id
                    console.log(this.roomId)
                    this.initData(initData)
                },
        methods:{
          addRoomCollection(){
            let that = this;
            let initData = {}
            initData.user_id = globaluserId()   //赵
            //initData.user_id = '116'  //原
            // 接收参数
            initData.room_id =  this.roomId

            addRoomCollection(initData).then(function (response) {
              responseUtil.dealResponse(that, response, () => {
                that.initData(initData)
                Notify({ type: 'success', message: '收藏成功' });
              })
            })
          },
          delRoomCollection(){
            let that = this;
            let initData = {}
            initData.user_id = globaluserId()   //赵
            //initData.user_id = '116'  //原
            // 接收参数
            initData.roomCollection_id =  this.roomCollection_id

            delRoomCollection(initData).then(function (response) {
              responseUtil.dealResponse(that, response, () => {
                that.initData(initData)
                Notify({ type: 'success', message: '取消成功' });
              })
            })
          },
          more(){
            this.$router.push(
                {
                  name: 'houseRelevant',
                  query: {
                    setHouse_id:this.initroom.setHouse_id,
                    room_id:this.initroom.room_id,
                    contractId:this.initroom.contractId,
                    roomType:this.initroom.roomType,
                    changeStatus:this.initroom.changeStatus,
                  }
                }
            )
          },
          showMap(){

            this.$router.push({name: 'viewAddress',query: { latitude: this.latitude, longitude:this.longitude,estateName:this.estateName}})
            // this.$router.push({ name: 'viewAddress', query: { room_id:this.roomId}});
          },

            //签约
            signContract(){

                if(this.roomStatusStr=='预到期'){
                    this.$router.push({
                        name: 'changeLease',
                        query: {
                            room_id: this.roomId,  // 天泉山庄-红旗东路73号4单元502号
                            setHouse_id:this.initroom.setHouse_id,
                            fromType: '2',
                            contractId: this.initroom.contractId,

                        }
                    })
                }else if(this.roomStatusStr=='转租'){
                    this.$router.push({
                        name: 'changeLease',
                        query: {
                            room_id: this.roomId, // 东特爱俪舍家酒店公寓-华北路（甘）2号1单元2022号
                            setHouse_id:this.initroom.setHouse_id,
                            fromType: '1',
                            contractId: this.initroom.contractId,

                        }
                    })
                } else{
                  this.$router.push({
                    name : 'NewContract',
                    query : {
                      roomId:this.roomId,        //赵
                      type:'1',
                      roomDetailedAddress:this.initroom.roomDetailedAddress
                    }
                  })
                }

            },

            //预定
            scheduled(){
                this.$router.push({
                    name : 'newReserve',
                  query:{
                    roomId: this.roomId,
                    type: '1'
                  }
                })
            },
            //预约看房
            appointmentSeeHouse(){
                this.$router.push({
                    name : 'addAppointment',
                    query : {
						roomId : this.room_id
                    }
                })
            },

            //电话咨询
            telephoneConsultation(){
              let that = this
              that.temporaryOwnerMobile = that.initroom.chargeStaffMobile

              that.isMobileShow = !that.isMobileShow
            },
          //关闭拨打电话弹窗
          hideDialog() {
            let that = this
            that.isMobileShow = false
          },
         //返回上一层
                    leftReturn(){
                        this.$router.go(-1);
                    },
          // 页面初始化
                    initData: function (initData) {
                      let that = this;
                      initData.user_id = globaluserId()   //赵
                      //initData.user_id = '116'  //原
                      // 接收参数
                      initData.room_id =  this.roomId

                        roomDetail(initData).then(function (response) {
                            responseUtil.dealResponse(that, response, () => {
                               //  console.log(response.data.data)
                                that.initroom = response.data.data
                                that.roomImages= response.data.data.roomPhotoList
                                that.roomItemList= response.data.data.roomItemList
                                that.roomInfoList= response.data.data.roomInfoList
                                that.roomFeatureList= response.data.data.roomFeatureList
                                that.latitude= response.data.data.latitude
                                that.longitude= response.data.data.longitude
                                that.estateName= response.data.data.estateName
                                that.room_id= response.data.data.room_id
                                that.roomCollection_id= response.data.data.roomCollection_id
                              if(that.roomCollection_id){
                                that.collectActive = true
                              }else{
                                that.collectActive = false
                              }
                            })
                        })
                    },
            showOrHidden(){
                this.showActive = !this.showActive
            },
            collect(){

              if(this.collectActive == false){
                this.addRoomCollection()
              }else if(this.collectActive == true){
                this.delRoomCollection()
              }
              this.collectActive = !this.collectActive
            }
        },
  //如果前往的页面不是推荐人，则摧毁缓存
  beforeRouteLeave: function(to, from, next) {
    if (from && to.name != 'NewContract' && to.name != 'preContract'&& to.name != 'addAppointment') {
      //此处判断是如果返回上一层，你可以根据自己的业务更改此处的判断逻辑，酌情决定是否摧毁本层缓存。
      if (this.$vnode && this.$vnode.data.keepAlive) {
        if (
          this.$vnode.parent &&
          this.$vnode.parent.componentInstance &&
          this.$vnode.parent.componentInstance.cache
        ) {
          if (this.$vnode.componentOptions) {
            var key =
              this.$vnode.key == null
                ? this.$vnode.componentOptions.Ctor.cid +
                  (this.$vnode.componentOptions.tag
                    ? `::${this.$vnode.componentOptions.tag}`
                    : '')
                : this.$vnode.key
            var cache = this.$vnode.parent.componentInstance.cache
            var keys = this.$vnode.parent.componentInstance.keys
            if (cache[key]) {
              if (keys.length) {
                var index = keys.indexOf(key)
                if (index > -1) {
                  keys.splice(index, 1)
                }
              }
              delete cache[key]
            }
          }
        }
      }
      this.$destroy()
    }
    next()
  },
        components:{
            [NavBar.name]:NavBar,
            [Swipe.name]:Swipe,
            [SwipeItem.name]:SwipeItem,
          dialMobile
        }
    }
</script>

<style scoped>
.more{
  position: fixed;
  top: 2%;
  z-index: 100;
  right: 3%;
  font-size: 10px;
  background-color: #999999;
  width: 40px;
  padding: 5px;
  border-radius: 20px;
  text-align: center;
  color: #f2f2f2;
}
.dispose{
  background-color: rgba(233, 91, 46, 0.2);
  color: rgba(255, 93, 59, 1);
  font-weight: 500;
  font-size: 10px;
  text-align: center;
  border-radius: 2px;
  padding: 1px 2px;
  margin-right: 8px;
}
    .swipe{
        top: 52px;
        width: 100%;
        height: 281px;
      margin-top: 50px;
    }
    .my-swipe{
        position: relative;
        width: 100%;
        height: 100%;
    }
    .my-swipe .van-swipe-item {
        width: 100%;
        height: 100%;
        color: #fff;
        font-size: 20px;
        text-align: center;
    }
    .image{
        color: red;
    }
    img{
        width: 100%;
        height: 100%;
    }
    .top-title{
        position: absolute;
        width: 29px;
        height: 14px;
        left: 333px;
        top: 257px;
        border-radius: 8px;
        background-color: rgba(0, 0, 0, 0.2);
        color: rgba(255, 255, 255, 1);
        font-size: 10px;
        text-align: center;
        font-family: PingFangSC-Regular;
    }
    .small-text-10{
        width: 100%;
        height: 100%;
        font-size: 20px;
        -webkit-transform: scale(0.5);
        white-space: nowrap;
        margin-top: -4px;
    }
    .house-describe{
        display: flex;
      /*align-items: flex-start;*/
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: auto;
        margin-top: 20px;

    }
    .house-describe-position{
        width: 270px;
        margin-left: 15px;
        /*margin-top: 20px;*/
        font-weight: bold;
        height: auto;
        line-height: 28px;
        font-size: 18px;
        color: #ff5a37;
    }
    .house-describe-position-span{
        line-height: 28px;
        color: black;
    }
    .house-describe-icon1{
        width: 22px;
        height: 40px;
        /*margin-left: 50px;*/
        /*margin-top: 21px;*/
      margin-right: 0.5rem;
    }
    .house-describe-icon2{
        width: 22px;
        height: auto;
        margin-left: 18px;
        margin-top: 21px;
    }
::v-deep .house-describe-icon1-left{
        width: 100%;
        height: 22px;
        background-image: url("../../../assets/images/houseResourcesImg/collection.png");
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
::v-deep .house-describe-icon1-leftActive{
        width: 100%;
        height: 22px;
        background-image: url("../../../assets/images/houseResourcesImg/collection-active.png");
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    .house-describe-icon1-right{
        width: 100%;
        height: 22px;
        background-image: url("../../../assets/images/houseResourcesImg/share.png");
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    .house-describe-icon1-text{
        width: 16px;
        height: 11px;
        text-align: center;
        line-height: 11px;
        margin-top: 3px;
        color: rgba(153, 153, 153, 1);
        font-size: 16px;
        text-align: left;
        font-family: PingFangSC-Regular;
        white-space: nowrap;
    }
    .small-text-8{
        width: 100%;
        height: 100%;
        -webkit-transform: scale(0.5);
    }
    .small-text-8Active{
        width: 100%;
        height: 100%;
      margin-left: -5px;
        color: rgba(255, 93, 59, 1);
        -webkit-transform: scale(0.5);
    }
    .infos{
        width: 364px;
        height: 50px;
    }
    .infos-price{
        float: left;
        width: auto;
        height: 32px;
        line-height: 35px;
        margin: 7px 0 0 20px;
        color: rgba(255, 93, 59, 1);
        font-size: 22px;
        text-align: left;
      font-weight: bold;
        font-family:Oswald;
        white-space: nowrap;
    }
    .infos-month{
        float: left;
        width: 21px;
        height: 20px;
        line-height: 20px;
        margin-left: 5px;
        margin-top: 18px;
        color: rgba(255, 93, 59, 1);
        font-size: 14px;
        text-align: left;
        font-family: PingFangSC-Regular;
    }
    .infos-info-1{
        float: right;
        width: auto;
        height: 14px;
        margin-top: 21px;
        margin-right: 1px;
        color: rgba(255, 93, 59, 1);
        font-family: PingFangSC-Regular;
        -webkit-transform: scale(0.83);
        background-color: rgba(233, 91, 46, 0.2);
        border-radius: 2px;
    }
    .infos-info-2{
        float: right;
        width: auto;
        height: 14px;
        margin-top: 21px;
        margin-right: 1px;
        color: rgba(86, 155, 236, 1);
        font-family: PingFangSC-Regular;
        -webkit-transform: scale(0.83);
        background-color: rgba(86, 155, 236, 0.2);
        border-radius: 2px;
    }
    .smallText-10{
        width: 100%;
        height: 100%;
        text-align: center;
        line-height: 15px;
        font-size: 12px;
        -webkit-transform: scale(0.83);
    }
    .details{
        width: 86%;
        /*height: 77px;*/
        margin-left: 17px;
        background-color: white;
        border-radius: 8px;
        display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 10px;
    }
    .details-detail-left{
        /*width: 115px;*/
    }
    .details-detail{
        /*width: 114px;*/
    }
    .details-line{
        width: 1px;
        height: 40px;
        margin-top: 22px;
        background-color: rgba(239, 239, 239, 1);
    }
    .details-detail-top{
        /*width: 100%;*/
        /*height: 17px;*/
        /*margin-top: 15px;*/
        /*line-height: 17px;*/
        color: rgba(153, 153, 153, 1);
        font-size: 12px;
        text-align: center;
        font-family: PingFangSC-Regular;
    }
    .details-detail-bottom{
        /*width: 100%;*/
        /*height: 22px;*/
        font-weight: bold;
        /*line-height: 22px;*/
        font-size: 16px;
        text-align: center;
      color: #242424;
      margin: 15px;
      font-family: PingFangSC-Semibold;
    }
    .inTime{
        width: 345px;
        height: 40px;
        margin-left: 17px;
        background-color: white;
        display: flex;
        border-radius: 8px;
        align-items: center;
        margin-top: 10px;
    }
    .inTime-icon{
        width: 20px;
        height: 20px;
        margin-left: 17px;
        background-image: url("../../../assets/images/mapt.png");
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    .inTime-info{
        width: auto;
        height: 18px;
        line-height: 21px;
        margin-left: 10px;
        font-size: 13px;
        text-align: left;
        white-space: nowrap;
      color: #ff5a37;
      font-weight: 600;
      font-family: PingFangSC-Regular;
    }
    .position{
        position: relative;
        width: 345px;
        height: 40px;
        margin-top: 10px;
        margin-left: 17px;
        background-color: white;
        display: flex;
        border-radius: 8px;
        align-items: center;
    }
    .position-icon{
        width: 22px;
        height: 22px;
        margin-left: 15px;
        background-image: url("../../../assets/images/houseResourcesImg/position.png");
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    .position-text{
        width: auto;
        height: 18px;
        margin-left: 8px;
        color: rgba(102, 102, 102, 1);
        font-size: 13px;
        text-align: left;
        font-family: PingFangSC-Regular;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .position-link{
        position: absolute;
        width: 48px;
        height: 17px;
        line-height: 17px;
        right: 15px;
        /*color: rgba(255, 93, 59, 1);*/
        font-size: 12px;
        text-align: left;
        font-family: PingFangSC-Regular;

      color: #ff5b35;
      font-weight: 600;
      /*font-size: 24rpx;*/
      /*font-family: PingFangSC-Regular;*/
    }
    .houseDetails{
        width: 100%;
        height: auto;
        background-color: white;
        margin-top: 15px;
        overflow: hidden;
    }
    .houseDetails-text{
        height: 24px;
        line-height: 24px;
        margin:20px 0 0 15px;
        color: rgba(34, 34, 34, 1);
        font-weight: bold;
        font-size: 17px;
        text-align: left;
        font-family: PingFangSC-Semibold;
    }
    .furnitures{
        display: flex;
        margin-top: 9px;
        width: 100%;
        height: auto;
        flex-wrap: wrap;
    }
    .furniture-one{
        width: 60px;
        height: 67px;
        margin-bottom: 30px;
        margin-left: 12.5px;
    }
    .furniture-one-icon{
        width: 40px;
        height: 40px;
        margin-left: 10px;
    }
    .furniture-one-text{
        width: 100%;
        height: 17px;
        margin-top: 10px;
        line-height: 17px;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: rgba(102, 102, 102, 1);
        font-size: 12px;
        font-family: PingFangSC-Regular;
    }
    .furniture-two{
        width: 90%;
        height: 67px;
        margin-top: 15px;
        margin-bottom: 15px;
        margin-left: 12.5px;
    }
    .furniture-two-icon{
        width: 40px;
        height: 40px;
        margin-left: 10px;
        display: inline-block;
    }
    .furniture-two-roomName{
        font-size: 14px;
        margin:0 20px;
        display: inline-block;
        position:relative;
        top:-15px;
    }
    .furniture-two-gender{
        font-size: 14px;
        margin:0 5px;
        display: inline-block;
        position:relative;
        top:-15px;
        color:#676767
    }
    .furniture-two-month{
        font-size: 14px;
        margin:0 5px;

        float:right;
        margin-top:15px;

    }
    .houseDetails-describe{
        height: 24px;
        line-height: 24px;
        margin:0 0 0 15px;
        color: rgba(34, 34, 34, 1);
        font-weight: bold;
        font-size: 17px;
        text-align: left;
        font-family: PingFangSC-Semibold;
    }
    .houseDetails-content{
        width: 100%;
        margin-top: 10px;
        height: auto;
    }
    .houseDetails-describe-details{
        width: 325px;
        height: auto;
        margin: 10px 25px 10px 25px;
        line-height: 20px;
        color: rgba(102, 102, 102, 1);
        font-size: 12px;
        text-align: left;
        font-family: PingFangSC-Regular;
        /* 文字展开 */
        /*关键属性*/
        display: -webkit-box;
        color:#676767;
        word-break: break-all;
        /* 关键属性 */
        -webkit-box-orient: vertical;
        /* 显示几行文字 */
        -webkit-line-clamp:6;
        /* 关键属性 */
        overflow: hidden;
    }
    .hide{
        display: -webkit-box;
    }
    .show{
        display: block;
        margin-bottom: 28px;
    }
    .bottom-box{
        width: 100%;
        height: 50px;
        position: relative;
        box-shadow: 0px -10px 20px 20px #ffffff;
    }
    .showOrHidden{
        position: absolute;
        right: 15px;
        width: auto;
        height: 17px;
        line-height: 17px;
        color: rgba(233, 91, 46, 1);
        font-size: 12px;
        text-align: right;
        font-family: PingFangSC-Regular;
    }
    .bottom-img{
        width: 100%;
        height: 121px;
        margin-bottom: 65px;
        background-image: url("../../../assets/images/houseResourcesImg/quality Guarantee.png");
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    .bottom-fixed{
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 65px;
        display: flex;
        align-items: center;
        justify-content: right;
        background-color: rgba(250, 250, 250, 1);
        box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.07);
    }
    .bottom-fixed-signed{
        width: 22px;
        height: 100%;
        margin-left: 32px;
    }
    .bottom-fixed-signed-icon{
        width: 20px;
        height: 20px;
        margin-top: 15px;
        margin-left: 1px;
        background-image: url("../../../assets/images/potentialHouse-icon1.png");
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    .bottom-fixed-text{
        width: 22px;
        height: 16px;
        margin-top: 5px;
    }
    .bottom-fixed-text-11{
        width: 100%;
        height: 100%;
        line-height: 16px;
        color: black;
        white-space: nowrap;
        font-family: PingFangSC-Regular;
        font-size: 12px;
        -webkit-transform: scale(0.92);
    }
    .bottom-fixed-order{
        width: 22px;
        height: 100%;
        margin-left: 41px;
    }
    .bottom-fixed-order-icon{
        width: 20px;
        height: 20px;
        margin-top: 15px;
        margin-left: 1px;
        background-image: url("../../../assets/images/houseResourcesImg/order.png");
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    .bottom-fixed-orderLook{
        width: 91px;
        height: 40px;
        line-height: 43px;
        margin-left: 48px;
        text-align: center;
        border-radius: 8px;
        background: linear-gradient(to right,#7cc7ff,#3596fd);
        box-shadow: 0px 1px 1px 0px rgba(0, 115, 202, 0.5);
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        font-family: PingFangSC-Semibold;
    }
    .bottom-fixed-phoneRelation{
        width: 91px;
        height: 40px;
        line-height: 43px;
        margin-left: 15px;
        margin-right: 15px;
        text-align: center;
        border-radius: 8px;
        background: linear-gradient(to right,#ffbe72,#ff6c41);
        box-shadow: 0px 1px 1px 0px rgba(188, 0, 0, 0.2);
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        font-family: PingFangSC-Semibold;
    }
</style>
